import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from './Box'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { space } from 'styled-system'

const ToggleWrapper = styled.div`
  display: flex;
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledInput = styled.input`
  cursor: pointer;
  min-height: 16px;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 28px;
  z-index: 1;

  & + span {
    background: ${props => props.theme.color.greyShade2};
    border-radius: 2em;
    border: 2px solid ${props => props.theme.color.greyShade2};
    cursor: pointer;
    display: inline-block;
    height: 12px;
    margin: 0;
    outline: 0;
    padding: 0;
    position: relative;
    transition: background-color ${props => props.theme.transition},
      border-color ${props => props.theme.transition};
    user-select: none;
    width: 24px;

    &:after {
      background: ${props => props.theme.color.white};
      border-radius: 12px;
      border: 2px solid transparent;
      content: '';
      display: block;
      height: 8px;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.3s;
      width: 8px;
    }
  }

  &:checked {
    & + span {
      background: ${props => props.theme.color.brandPrimary};
      border-color: ${props => props.theme.color.brandPrimary};
      &:after {
        transform: translateX(12px);
      }
    }
    &:hover {
      & + span {
        border-color: ${props => props.theme.group1shade9};
      }
    }
  }

  &:hover {
    & + span {
      border-color: ${props => props.theme.color.brandPrimary};
      &:after {
        box-shadow: ${props => props.theme.color.boxShadow};
      }
    }
  }
  &:focus {
    & + span {
      box-shadow: ${props => props.theme.color.boxShadow};
    }
  }

  &:disabled {
    cursor: not-allowed;

    &:hover,
    &:focus {
      & + span {
        border: 2px solid ${props => props.theme.color.greyShade5};
      }
    }
  }
`

const ToggleItself = ({ id, name, checked, onChange, ...rest }) => (
  <InnerWrapper>
    <StyledInput
      id={id}
      name={name}
      type="checkbox"
      className="toggle"
      checked={checked}
      onChange={onChange}
      {...rest}
    />
    <span />
  </InnerWrapper>
)

const StyledLabel = styled.div`
  align-self: center;
  align-items: center;

  & > label {
    padding: ${props =>
      props.direction === 'left' ? '0 8px 0 0' : '0 0 0 8px'};
    cursor: pointer;
    line-height: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: ${props => props.theme.primaryType};
    font-size: 16px;
  }
`

const Label = ({ id, checked, checkedLabel, uncheckedLabel, direction }) => (
  <StyledLabel className="toggle-left" direction={direction}>
    <label htmlFor={id}>
      {checked && checkedLabel}
      {!checked && uncheckedLabel}
    </label>
  </StyledLabel>
)

const Title = styled.p`
  font-size: 12px;
  margin: 0 0 4px;
  color: ${props => props.theme.color.greyShade5};
`

export const Toggle = ({
  checked = false,
  checkedLabel,
  children,
  direction = 'right',
  id,
  title,
  name,
  onChange,
  uncheckedLabel,
  ...rest
}) => (
  <Box {...pick(rest, [...Object.keys(space)])}>
    {title && <Title>{title}</Title>}
    <ToggleWrapper className="toggle-wrapper">
      {direction === 'left' && (
        <Label
          id={id}
          checked={checked}
          checkedLabel={checkedLabel}
          uncheckedLabel={uncheckedLabel}
          direction={direction}
        />
      )}
      <ToggleItself
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        {...omit(rest, [...Object.keys(space)])}
      />
      {direction === 'right' && (
        <Label
          id={id}
          checked={checked}
          checkedLabel={checkedLabel}
          uncheckedLabel={uncheckedLabel}
          direction={direction}
        />
      )}
    </ToggleWrapper>
  </Box>
)

Toggle.propTypes = {
  checked: PropTypes.bool,
  checkedLabel: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.node,
  uncheckedLabel: PropTypes.node,
  children: PropTypes.node
}

Toggle.defaultProps = {
  input: {
    value: false
  },
  meta: {
    touched: false,
    error: false
  }
}

export default Toggle
