import styled from 'styled-components'

import { space, color, typography } from 'styled-system'

const H1 = styled.h1`
  color: ${props => props.theme.primaryType};
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes.basePlus6};
  font-weight: bold;
  ${color};
  ${space};
  ${typography};
`

const H1Alt = styled(H1)`
  font-family: ${props => props.theme.fonts.secondary};
`

const H2 = styled.h2`
  font-family: ${props => props.theme.fonts.primary};
  ${typography}
  color: ${props => props.theme.primaryType};
  font-size: ${props => props.theme.fontSizes.basePlus5};
  font-weight: bold;
  ${color};
  ${space};
  ${typography};
`
const H2Alt = styled(H2)`
  font-family: ${props => props.theme.fonts.secondary};
`
const H3 = styled.h3`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.primaryType};
  font-size: ${props => props.theme.fontSizes.basePlus4};
  font-weight: bold;
  ${color};
  ${space};
  ${typography};
`
const H3Alt = styled(H3)`
  font-family: ${props => props.theme.fonts.secondary};
`
const H4 = styled.h4`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.primaryType};
  font-size: ${props => props.theme.fontSizes.basePlus3};
  ${color};
  ${space};
  ${typography};
`
const H5 = styled.h5`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.primaryType};
  font-size: ${props => props.theme.fontSizes.basePlus2};
  ${color};
  ${space};
  ${typography};
`
const H6 = styled.h6`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.primaryType};
  font-size: ${props => props.theme.fontSizes.basePlus1};
  font-weight: bold;
  ${color};
  ${space};
  ${typography};
`
const P = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.primaryType};
  font-size: ${props => props.theme.fontSizes.base};
  ${color};
  ${space};
  ${typography};
`
const SM = styled.p`
  color: ${props => props.theme.primaryType};
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes.baseMinus1};
  ${color};
  ${space};
  ${typography};
`

const XS = styled(SM)`
  font-size: ${props => props.theme.fontSizes.baseMinus2};
`

export { H1, H1Alt, H2, H2Alt, H3, H3Alt, H4, H5, H6, P, SM, XS }
